/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
  overflow-x: hidden; }

.container-fluid {
  padding: 0; }

#sidepanel {
  min-height: calc(100vh - 56px);
  margin-left: -280px;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out; }

#sidepanel .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem; }

#sidepanel .list-group {
  width: 280px; }

#page-content-wrapper {
  min-width: 100vw; }

#wrapper.toggled #sidepanel {
  margin-left: 0; }

@media (min-width: 768px) {
  #sidepanel {
    margin-left: 0; }
  #page-content-wrapper {
    min-width: 0;
    width: 100%; }
  #wrapper.toggled #sidepanel {
    margin-left: -280px; } }

.ChatBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 56px);
  font-family: 'proxima-nova', 'Source Sans Pro', sans-serif;
  font-size: 1em;
  letter-spacing: 0.1px;
  color: #32465a;
  text-rendering: optimizeLegibility;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;
  list-style: none !important; }
  .ChatBox html, .ChatBox body, .ChatBox div, .ChatBox span, .ChatBox applet, .ChatBox object, .ChatBox iframe, .ChatBox h1, .ChatBox h2, .ChatBox h3, .ChatBox h4, .ChatBox h5, .ChatBox h6, .ChatBox p, .ChatBox blockquote, .ChatBox pre, .ChatBox a, .ChatBox abbr, .ChatBox acronym, .ChatBox address, .ChatBox big, .ChatBox cite, .ChatBox code, .ChatBox del, .ChatBox dfn, .ChatBox em, .ChatBox img, .ChatBox ins, .ChatBox kbd, .ChatBox q, .ChatBox s, .ChatBox samp, .ChatBox small, .ChatBox strike, .ChatBox strong, .ChatBox sub, .ChatBox sup, .ChatBox tt, .ChatBox var, .ChatBox b, .ChatBox u, .ChatBox center, .ChatBox dl, .ChatBox dt, .ChatBox dd, .ChatBox ol, .ChatBox ul, .ChatBox li, .ChatBox fieldset, .ChatBox form, .ChatBox label, .ChatBox legend, .ChatBox table, .ChatBox caption, .ChatBox tbody, .ChatBox tfoot, .ChatBox thead, .ChatBox tr, .ChatBox th, .ChatBox td, .ChatBox article, .ChatBox aside, .ChatBox canvas, .ChatBox details, .ChatBox embed, .ChatBox figure, .ChatBox figcaption, .ChatBox footer, .ChatBox header, .ChatBox hgroup, .ChatBox menu, .ChatBox nav, .ChatBox output, .ChatBox ruby, .ChatBox section, .ChatBox summary, .ChatBox time, .ChatBox mark, .ChatBox audio, .ChatBox video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline; }
  .ChatBox article, .ChatBox aside, .ChatBox details, .ChatBox figcaption, .ChatBox figure, .ChatBox footer, .ChatBox header, .ChatBox hgroup, .ChatBox menu, .ChatBox nav, .ChatBox section {
    display: block; }
  .ChatBox body {
    line-height: 1; }
  .ChatBox ol, .ChatBox ul {
    list-style: none; }
  .ChatBox blockquote, .ChatBox q {
    quotes: none; }
  .ChatBox blockquote:before, .ChatBox blockquote:after, .ChatBox q:before, .ChatBox q:after {
    content: '';
    content: none; }
  .ChatBox table {
    border-collapse: collapse;
    border-spacing: 0; }

#chat-frame {
  width: 100%;
  min-width: 360px;
  height: 92vh;
  min-height: 300px;
  max-height: 720px;
  background: #e6eaea; }

@media screen and (max-width: 360px) {
  #chat-frame {
    width: 100%;
    height: 100vh; } }

#chat-frame #sidepanel {
  float: left;
  min-width: 280px;
  max-width: 280px;
  width: 40%;
  height: calc(100vh - 56px);
  background: #2c3e50;
  color: #f5f5f5;
  overflow: hidden;
  position: relative; }

@media screen and (max-width: 735px) {
  #chat-frame #sidepanel {
    width: 280px; } }

#chat-frame #sidepanel #profile {
  width: 80%;
  margin: 25px auto; }

@media screen and (max-width: 735px) {
  #chat-frame #sidepanel #profile {
    width: 100%;
    margin: 0 auto;
    padding: 5px 0 0 0;
    background: #32465a; } }

#chat-frame #sidepanel #profile.expanded .wrap {
  height: 210px;
  line-height: initial; }

#chat-frame #sidepanel #profile.expanded .wrap p {
  margin-top: 20px; }

#chat-frame #sidepanel #profile.expanded .wrap i.expand-button {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
  filter: FlipH;
  -ms-filter: 'FlipH'; }

#chat-frame #sidepanel #profile .wrap {
  height: 60px;
  line-height: 60px;
  overflow: hidden;
  -moz-transition: 0.3s height ease;
  -o-transition: 0.3s height ease;
  -webkit-transition: 0.3s height ease;
  transition: 0.3s height ease; }

@media screen and (max-width: 735px) {
  #chat-frame #sidepanel #profile .wrap {
    height: 55px; } }

#chat-frame #sidepanel #profile .wrap img {
  width: 50px;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid #e74c3c;
  height: auto;
  float: left;
  cursor: pointer;
  -moz-transition: 0.3s border ease;
  -o-transition: 0.3s border ease;
  -webkit-transition: 0.3s border ease;
  transition: 0.3s border ease; }

@media screen and (max-width: 735px) {
  #chat-frame #sidepanel #profile .wrap img {
    width: 40px;
    margin-left: 4px; } }

#chat-frame #sidepanel #profile .wrap img.online {
  border: 2px solid #2ecc71; }

#chat-frame #sidepanel #profile .wrap img.away {
  border: 2px solid #f1c40f; }

#chat-frame #sidepanel #profile .wrap img.busy {
  border: 2px solid #e74c3c; }

#chat-frame #sidepanel #profile .wrap img.offline {
  border: 2px solid #95a5a6; }

#chat-frame #sidepanel #profile .wrap p {
  float: left;
  margin-left: 15px; }

#chat-frame #sidepanel #profile .wrap i.expand-button {
  float: right;
  margin-top: 23px;
  font-size: 0.8em;
  cursor: pointer;
  color: #435f7a; }

#chat-frame #sidepanel #profile .wrap #status-options {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 150px;
  margin: 70px 0 0 0;
  border-radius: 6px;
  z-index: 99;
  line-height: initial;
  background: #435f7a;
  -moz-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  transition: 0.3s all ease; }

#chat-frame #sidepanel #profile .wrap #status-options.active {
  opacity: 1;
  visibility: visible;
  margin: 75px 0 0 0; }

#chat-frame #sidepanel #profile .wrap #status-options:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 8px solid #435f7a;
  margin: -8px 0 0 24px; }

#chat-frame #sidepanel #profile .wrap #status-options ul {
  overflow: hidden;
  border-radius: 6px; }

#chat-frame #sidepanel #profile .wrap #status-options ul li {
  padding: 15px 0 30px 18px;
  display: block;
  cursor: pointer; }

#chat-frame #sidepanel #profile .wrap #status-options ul li:hover {
  background: #496886; }

#chat-frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 5px 0 0 0; }

#chat-frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  margin: -3px 0 0 -3px;
  background: transparent;
  border-radius: 50%;
  z-index: 0; }

#chat-frame #sidepanel #profile .wrap #status-options ul li p {
  padding-left: 12px; }

#chat-frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
  background: #2ecc71; }

#chat-frame
#sidepanel
#profile
.wrap
#status-options
ul
li#status-online.active
span.status-circle:before {
  border: 1px solid #2ecc71; }

#chat-frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
  background: #f1c40f; }

#chat-frame
#sidepanel
#profile
.wrap
#status-options
ul
li#status-away.active
span.status-circle:before {
  border: 1px solid #f1c40f; }

#chat-frame #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
  background: #e74c3c; }

#chat-frame
#sidepanel
#profile
.wrap
#status-options
ul
li#status-busy.active
span.status-circle:before {
  border: 1px solid #e74c3c; }

#chat-frame #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
  background: #95a5a6; }

#chat-frame
#sidepanel
#profile
.wrap
#status-options
ul
li#status-offline.active
span.status-circle:before {
  border: 1px solid #95a5a6; }

#chat-frame #sidepanel #profile .wrap #expanded {
  padding: 100px 0 0 0;
  display: block;
  line-height: initial !important; }

#chat-frame #sidepanel #profile .wrap #expanded label {
  float: left;
  clear: both;
  margin: 0 8px 5px 0;
  padding: 5px 0; }

#chat-frame #sidepanel #profile .wrap #expanded input {
  border: none;
  margin-bottom: 6px;
  background: #32465a;
  border-radius: 3px;
  color: #f5f5f5;
  padding: 7px;
  width: calc(100% - 43px); }

#chat-frame #sidepanel #profile .wrap #expanded input:focus {
  outline: none;
  background: #435f7a; }

#chat-frame #sidepanel #search {
  border-top: 1px solid #32465a;
  border-bottom: 1px solid #32465a;
  font-weight: 300; }

#chat-frame #sidepanel #search label {
  position: absolute;
  margin: 10px 0 0 20px; }

#chat-frame #sidepanel #search input {
  font-family: 'proxima-nova', 'Source Sans Pro', sans-serif;
  padding: 10px 0 10px 46px;
  width: calc(100% - 25px);
  border: none;
  background: #32465a;
  color: #f5f5f5; }

#chat-frame #sidepanel #search input:focus {
  outline: none;
  background: #435f7a; }

#chat-frame #sidepanel #search input::-webkit-input-placeholder {
  color: #f5f5f5; }

#chat-frame #sidepanel #search input::-moz-placeholder {
  color: #f5f5f5; }

#chat-frame #sidepanel #search input:-ms-input-placeholder {
  color: #f5f5f5; }

#chat-frame #sidepanel #search input:-moz-placeholder {
  color: #f5f5f5; }

#chat-frame #sidepanel #contacts {
  margin-top: 20px;
  height: calc(100% - 177px);
  overflow-y: scroll;
  overflow-x: hidden;
  list-style: none; }
  #chat-frame #sidepanel #contacts .btn.btn-link {
    text-decoration: none; }
  #chat-frame #sidepanel #contacts .collapse.show {
    background: #2c3e50; }
  #chat-frame #sidepanel #contacts .card {
    background: #2c3e50; }
  #chat-frame #sidepanel #contacts .card-header {
    background: #2c3e50;
    outline: none;
    color: #f5f5f5; }
    #chat-frame #sidepanel #contacts .card-header button {
      color: #f5f5f5;
      font-weight: bolder; }

#chat-frame #sidepanel #contacts.expanded {
  height: calc(100% - 334px); }

#chat-frame #sidepanel #contacts::-webkit-scrollbar {
  width: 8px;
  background: #2c3e50; }

#chat-frame #sidepanel #contacts::-webkit-scrollbar-thumb {
  background-color: #243140; }

#chat-frame #sidepanel #contacts ul li.contact {
  position: relative;
  padding: 10px 0 15px 0;
  font-size: 0.9em;
  cursor: pointer; }

#chat-frame #sidepanel #contacts ul li.contact:hover {
  background: #32465a; }

#chat-frame #sidepanel #contacts ul li.contact.active {
  background: #32465a;
  border-right: 5px solid #435f7a; }

#chat-frame #sidepanel #contacts ul li.contact.active span.contact-status {
  border: 2px solid #32465a !important; }

#chat-frame #sidepanel #contacts ul li.contact .wrap {
  width: 88%;
  margin: 0 auto;
  position: relative; }

#chat-frame #sidepanel #contacts ul li.contact .wrap span {
  position: absolute;
  left: 0;
  margin: -2px 0 0 -2px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #2c3e50;
  background: #95a5a6; }

#chat-frame #sidepanel #contacts ul li.contact .wrap span.online {
  background: #2ecc71; }

#chat-frame #sidepanel #contacts ul li.contact .wrap span.away {
  background: #f1c40f; }

#chat-frame #sidepanel #contacts ul li.contact .wrap span.busy {
  background: #e74c3c; }

#chat-frame #sidepanel #contacts ul li.contact .wrap img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin-right: 10px; }

#chat-frame #sidepanel #contacts ul li.contact .wrap .meta {
  padding: 5px 0 0 0; }

#chat-frame #sidepanel #contacts ul li.contact .wrap .meta .name {
  font-weight: 600; }

#chat-frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
  margin: 5px 0 0 0;
  padding: 0 0 1px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -moz-transition: 1s all ease;
  -o-transition: 1s all ease;
  -webkit-transition: 1s all ease;
  transition: 1s all ease; }

#chat-frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
  position: initial;
  border-radius: initial;
  background: none;
  border: none;
  padding: 0 2px 0 0;
  margin: 0 0 0 1px;
  opacity: 0.5; }

#chat-frame #sidepanel #contacts ul {
  padding-left: 5px; }

#chat-frame #sidepanel #bottom-bar {
  position: absolute;
  width: 100%;
  bottom: 0; }

#chat-frame #sidepanel #bottom-bar button {
  float: left;
  border: none;
  width: 50%;
  padding: 10px 0;
  background: #32465a;
  color: #f5f5f5;
  cursor: pointer;
  font-size: 0.85em;
  font-family: 'proxima-nova', 'Source Sans Pro', sans-serif; }

#chat-frame #sidepanel #bottom-bar button:focus {
  outline: none; }

#chat-frame #sidepanel #bottom-bar button:nth-child(1) {
  border-right: 1px solid #2c3e50; }

#chat-frame #sidepanel #bottom-bar button:hover {
  background: #435f7a; }

#chat-frame #sidepanel #bottom-bar button i {
  margin-right: 3px;
  font-size: 1em; }

@media screen and (max-width: 735px) {
  #chat-frame #sidepanel #bottom-bar button i {
    font-size: 1.3em; } }

#chat-frame .content {
  float: right;
  width: calc(100% - 280px);
  height: 100%;
  overflow: hidden;
  position: relative; }

#chat-frame .content .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f5f5f5; }

#chat-frame .content .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin: 9px 12px 0 9px; }

#chat-frame .content .contact-profile p {
  float: left; }

#chat-frame .content .contact-profile .social-media {
  float: right; }

#chat-frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer; }

#chat-frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px; }

#chat-frame .content .contact-profile .social-media i:hover {
  color: #435f7a; }

#chat-frame .content .messages {
  height: auto;
  min-height: calc(100% - 93px);
  max-height: calc(100% - 93px);
  overflow-y: scroll;
  overflow-x: hidden; }

@media screen and (max-width: 735px) {
  #chat-frame .content .messages {
    max-height: calc(100% - 105px); } }

#chat-frame .content .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent; }

#chat-frame .content .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); }

#chat-frame .content .messages ul li {
  display: inline-block;
  clear: both;
  float: left;
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  font-size: 0.9em;
  word-wrap: break-word; }

#chat-frame .content .messages ul li:nth-last-child(1) {
  margin-bottom: 20px; }

#chat-frame .content .messages ul li.sent img {
  float: right;
  margin: 6px 8px 0 0; }

#chat-frame .content .messages ul li.sent > div {
  float: right;
  text-align: right;
  color: #f5f5f5; }

#chat-frame .content .messages ul li.sent span {
  float: right;
  color: #32465a; }

#chat-frame .content .messages ul li.sent p {
  background: #435f7a; }

#chat-frame .content .messages ul li.replies img {
  margin: 6px 0 0 8px; }

#chat-frame .content .messages ul li img {
  width: 22px;
  border-radius: 50%;
  float: left; }

#chat-frame .content .messages ul li p {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 205px;
  line-height: 130%;
  margin-bottom: 0.5rem; }

@media screen and (min-width: 735px) {
  #chat-frame .content .messages ul li p {
    max-width: 300px; }
  #chat-frame .content .messages ul li.replies div {
    max-width: 300px; } }

#chat-frame .content .message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99; }

#chat-frame .content .message-input .wrap {
  position: relative; }

#chat-frame .content .message-input .wrap input {
  font-family: 'proxima-nova', 'Source Sans Pro', sans-serif;
  float: left;
  border: none;
  width: calc(100% - 90px);
  padding: 11px 32px 10px 8px;
  font-size: 0.8em;
  color: #32465a; }

@media screen and (max-width: 735px) {
  #chat-frame .content .message-input .wrap input {
    padding: 15px 32px 16px 8px; } }

#chat-frame .content .message-input .wrap input:focus {
  outline: none; }

#chat-frame .content .message-input .wrap button {
  float: right;
  border: none;
  width: 50px;
  padding: 12px 0;
  cursor: pointer;
  background: #32465a;
  color: #f5f5f5; }

@media screen and (max-width: 735px) {
  #chat-frame .content .message-input .wrap button {
    padding: 16px 0; } }

#chat-frame .content .message-input .wrap button:hover {
  background: #435f7a; }

#chat-frame .content .message-input .wrap button:focus {
  outline: none; }

.claimRoomButton {
  background-color: rgba(0, 0, 0, 0.08); }

.chatMobileToggler {
  background-color: rgba(0, 0, 0, 0.08);
  margin-left: 25px; }
  @media (max-width: 767px) {
    .chatMobileToggler {
      display: block; } }
  @media (min-width: 768px) {
    .chatMobileToggler {
      display: none !important; } }
  @media (max-width: 735px) {
    .chatMobileToggler#profile-img {
      display: none; } }

.typingIndicatorBubble .dot {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  display: inline-block;
  background-color: #b4b5b9;
  animation: 1.2s typing-dot ease-in-out infinite; }

.typingIndicatorBubble .dot:nth-of-type(2) {
  animation-delay: 0.15s; }

.typingIndicatorBubble .dot:nth-of-type(3) {
  animation-delay: 0.25s; }

@keyframes typing-dot {
  15% {
    transform: translateY(-35%);
    opacity: 0.5; }
  30% {
    transform: translateY(0%);
    opacity: 1; } }

.ring-container {
  position: absolute;
  bottom: 40px;
  right: 20px;
  width: 30px;
  height: 30px; }

.ringContainerChild {
  position: relative; }

.circle {
  width: 15px;
  height: 15px;
  background-color: #d82104;
  border-radius: 50%;
  position: absolute;
  top: 28px;
  left: 28px; }

.ringring {
  border: 3px solid #D82104 !important;
  background-color: #d82104;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  top: 23px;
  left: 23px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0; }

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0, 0);
    opacity: 0; }
  50% {
    opacity: 1; }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0; } }

.botMessage {
  background-color: #007bff !important;
  color: white; }

.replies .userMessage {
  margin-bottom: 1rem; }
  .replies .userMessage p {
    background-color: #f5f5f5;
    color: black; }

.input-container {
  display: flex;
  flex: 1 1;
  position: relative; }
  .input-container > input {
    flex: 1 1; }
  .input-container > div {
    position: absolute;
    right: 0; }
    .input-container > div > i {
      z-index: 4;
      margin-top: 10px;
      font-size: 1.1em;
      color: #435f7a;
      opacity: 0.5;
      cursor: pointer;
      margin-right: 1rem; }
      .input-container > div > i:hover {
        opacity: 1; }

.badge {
  text-transform: uppercase;
  position: relative;
  bottom: 1px;
  vertical-align: middle;
  background-color: #15c39a;
  border-radius: 5px;
  padding: 7px;
  color: white;
  margin-right: 0.5rem; }

.textarea {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  font: medium -moz-fixed;
  font: -webkit-small-control;
  overflow: auto;
  padding: 2px;
  resize: both;
  width: 400px;
  height: 50px; }

.shortcut-input {
  border: none;
  border-bottom: 1px solid black;
  outline: none; }
