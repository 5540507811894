/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template-overviews/simple-sidebar)
 * Copyright 2013-2019 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */
body {
  overflow-x: hidden;
}

.container-fluid {
  padding: 0;
}

#sidepanel {
  //56 px is navbar height
  min-height: calc(100vh - 56px);
  // min-height: 100vh;
  margin-left: -280px;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidepanel .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidepanel .list-group {
  width: 280px;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidepanel {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidepanel {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidepanel {
    margin-left: -280px;
  }
}
